/*************************************************
 * HotDeck
 * @exports
 * MainLayout.tsx
 * Created by venugopal on 14/04/2024
 * Copyright © 2024 HotDeck. All rights reserved.
 *************************************************/

//import

import SideBar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
  return (
    <>
      <div className="flex h-screen md:flex-row">
        <SideBar />
        <div className="flex flex-col flex-grow overflow-hidden">
          <div className="flex-shrink-0 bg-muted/40">
            <Header />
          </div>
          <div className="flex flex-col flex-grow overflow-auto bg-muted/40">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
